import React, {useState} from 'react';

import {
    Checkbox,
    Form,
    Input,
    InputNumber,
    Radio,
    Button,
    message,
} from 'antd';

import viatouchLogoBlack from "./../../../assets/img/order-page/viatouch-logo-black.png";
import planetFitnessLogo from "../../../assets/img/planet-fitness-logo.webp";
import './Readiness-form.scss';

function ReadinessForm(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish =  async (formValue) => {
        setLoading(true);

        try {
            const response = await fetch('https://viatouchmedia-test.apigee.net/loyalty/microsite/readiness?to=viktor.kazmirchuk@oril.co', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': props.accessToken
                },
                body: JSON.stringify(formValue)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Something went wrong!');
            }

            setLoading(false);
            messageApi.open({
                type: 'success',
                content: 'Readiness form successfully submitted!',
            });
            form.resetFields();
        } catch (err) {
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: 'Failed to submit Readiness form: ' + err.message,
            });
            console.log(err);
        }
    };

    return (
        <div className={'order-form-wrapper'}>
            {contextHolder}
            <div className={'logos-wrapper d-flex justify-content-between'}>
                <img src={planetFitnessLogo} alt="Planet Fitness"/>
                <img src={viatouchLogoBlack} alt="Planet Fitness"/>
            </div>
            
            <div className={'body-text mb-50'}>
                Use this checklist when performing site visits to ensure the necessary electrical,
                internet and space requirements are in place for the vending model below.
            </div>
            
            <div className={'body-text mb-50'}>
                PLANET FITNESS - VICKI by ViaTouch Media (Standard Planogram)
            </div>
            
            <Form
                form={form}
                layout="horizontal"
                labelAlign="left"
                labelWrap={true}
                colon={false}
                scrollToFirstError={true}
                onFinish={onFinish}
            >
            <div className={'order-form-section'}>
                <div className={'order-section-title'}>MACHINE FOOTPRINT/LOCATION REQUIREMENTS:</div>
                <div className={'order-section-form'}>

                    9 sq. ft.

                    <div className={'measurements-table'}>
                        <div className={'measurement-unit'}>
                            <div className={'measurement-unit-name'}>Width</div>
                            <div className={'measurement-unit-value'}>35.7 in. (90.7 cm)</div>
                        </div>
                        <div className={'measurement-unit'}>
                            <div className={'measurement-unit-name'}>Depth**</div>
                            <div className={'measurement-unit-value'}>36.24 in. (92 cm)</div>
                        </div>
                        <div className={'measurement-unit'}>
                            <div className={'measurement-unit-name'}>Height</div>
                            <div className={'measurement-unit-value'}>81.65 in. (207.4 cm)</div>
                        </div>
                        <div className={'measurement-unit'}>
                            <div className={'measurement-unit-name'}>Weight</div>
                            <div className={'measurement-unit-value'}>850 lb. (386 kg)</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'order-form-section'}>
                <div className={'order-section-title'}>SITE ACCESS:</div>
                <div className={'order-section-form'}>
                    
                    <div className={'d-flex flex-wrap gap-30'}>
                        <div className={'d-flex flex-column'}>
                            <div className={'body-text text-underline text-bold mb-10'}>On Pallet</div>
                            <div className={'body-text'}>Door Opening Width: 42”</div>
                            <div className={'body-text'}>Door Opening Height: 86”</div>
                        </div>
                        <div className={'d-flex flex-column'}>
                            <div className={'body-text text-underline text-bold mb-10'}>Off Pallet</div>
                            <div className={'body-text'}>Door Opening Width: 36”</div>
                            <div className={'body-text'}>Door Opening Height: 82”</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'order-form-section'}>
                <div className={'order-section-title'}>MACHINE LOCATION:</div>
                <div className={'order-section-form'}>
                    <div className={'d-flex flex-wrap gap-30'}>
                        <div className={'d-flex flex-column'}>
                            <div className={'body-text'}>Indoor – protected</div>
                            <div className={'body-text'}>Clear of public thoroughfare</div>
                            <div className={'body-text'}>Clearance for door to open 90°</div>
                        </div>
                        <div className={'d-flex flex-column'}>
                            <div className={'body-text'}>Clear of direct sunlight</div>
                            <div className={'body-text'}>Clear of emergency exits</div>
                            <div className={'body-text'}>Soft floor – requires support cups</div>
                        </div>
                    </div>

                    <div className={'d-flex flex-column'}>
                        <div className={'body-text'}>Other special requirements</div>
                        <Form.Item name="otherSpecRequirements">
                            <Input />
                        </Form.Item>
                    </div>
                </div>
            </div>

            <div className={'order-form-section'}>
                <div className={'order-section-title'}>LOCATION ACCESS:</div>
                <div className={'order-section-form'}>
                    <div className={'d-flex flex-wrap gap-30'}>
                        <Form.Item name="receivingDock"
                                   valuePropName="checked">
                            <Checkbox>Receiving dock</Checkbox>
                        </Form.Item>

                        <Form.Item name="forkliftAvailable"
                                   valuePropName="checked">
                            <Checkbox>Forklift available</Checkbox>
                        </Form.Item>

                        <Form.Item name="liftGateRequired"
                                   valuePropName="checked">
                            <Checkbox>Lift gate required</Checkbox>
                        </Form.Item>

                        <Form.Item name="palletTruckAccess"
                                   valuePropName="checked">
                            <Checkbox>Pallet truck access</Checkbox>
                        </Form.Item>

                        <Form.Item name="stairs"
                                   valuePropName="checked">
                            <Checkbox>Stairs (need crawler)</Checkbox>
                        </Form.Item>

                        <Form.Item name="floorProtectionRequired"
                                   valuePropName="checked">
                            <Checkbox>Floor protection required</Checkbox>
                        </Form.Item>

                        <Form.Item label="# Hallways to location"
                                   name="hallwaysToLocation">
                            <InputNumber />
                        </Form.Item>

                        <Form.Item label="# Single-entry doors to location"
                                   name="singleEntryDoorsToLocation">
                            <InputNumber />
                        </Form.Item>

                        <Form.Item label="Single-entry door width(s)"
                                   name="singleEntryDoorWidth">
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>
            </div>

            <div className={'order-form-section'}>
                <div className={'order-section-title'}>MACHINE REQUIREMENTS:</div>
                <div className={'order-section-form'}>
                    <div className={'d-flex flex-column gap-30'}>
                        <Form.Item name="airFlow"
                                   valuePropName="checked">
                            <Checkbox>AIR FLOW: Ensure clear space of 4” (102mm) at the back and 2” (51mm) above the machine</Checkbox>
                        </Form.Item>
                        <Form.Item name="electrical"
                                   valuePropName="checked">
                            <Checkbox>ELECTRICAL: Dedicated 120V, 60Hz, 15/20AMP circuit/wall socket within 5ft of machine location</Checkbox>
                        </Form.Item>
                    </div>
                </div>
            </div>

            <div className={'order-form-section'}>
                <div className={'order-section-title'}>ETHERNET REQUIRED:</div>
                <div className={'order-section-form'}>
                    <Form.Item name="ethernetRequired"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <Radio.Group>
                            <Radio value="true"> Yes </Radio>
                            <Radio value="false"> No (Default connectivity is cellular unless Ethernet is required) </Radio>
                        </Radio.Group>
                    </Form.Item>
                    
                </div>
            </div>

            <div className={'order-form-section'}>
                <div className={'order-section-title'}>GRAPHICS:</div>
                <div className={'order-section-form'}>
                    <Form.Item label="Quantity of “Stand Alone” Units"
                               name="standAloneUnits"
                               extra={'*Recessed units are preferred by PFHQ where option is available'}
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <InputNumber />
                    </Form.Item>
                </div>
            </div>

            <div className={'order-form-section'}>
                <div className={'order-section-title'}>LOCATION DETAILS:</div>
                <div className={'order-section-form'}>
                    <Form.Item label="Location Name"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               name="locationName"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Quantity of Vickis"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               name="quantityOfVickis"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Site Contact Name"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               name="siteContactName"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Phone"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               name="phone"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Location Address"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               name="address"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="City"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               name="city"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="State/Province"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               name="state"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Zip Code"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               name="zip"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Sales Tax % for products sold in Vicki"
                               labelCol={{span: 6}}
                               wrapperCol={{span: 18}}
                               extra={'*having this completed will allow us to preload Sales Tax rate to machines'}
                               name="salesTax"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Field is required',
                                   },
                               ]}>
                        <InputNumber />
                    </Form.Item>
                </div>
            </div>

            <div className={'order-form-section'}>
                <div className={'order-section-title'}></div>
                <div className={'order-section-form'}>
                    <Form.Item wrapperCol={{span: 24}}>
                        <Button type="primary"
                                loading={loading}
                                htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </div>
            </Form>
        </div>
    );
}

export default ReadinessForm;
