import React, {useState} from 'react';

import {
    Form,
    Input,
    InputNumber,
    Radio,
    Button,
    message,
} from 'antd';

import './Order-form.scss';

function OrderForm(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const salesTax = 10;

    const productPropertyNames = [
        'vickiSmartStore',
        'brandedGraphicsFront',
        'brandedGraphicsWrapped',
        'onsiteInstallationAndTraining',
        'flatRateShipping',
        'softwareAndSalesSupport',
    ];

    const vickiKioskPricing = [
        {
            unitName: 'vickiSmartStore',
            label: 'VICKI Smart Store with Zebra Badge Scanner & CV Nano Camera',
            validationRules: [{required: true, message: 'Field is required'}],
            retailPrice: 10895,
            wholesalePrice: 8927,
        },
        {
            unitName: 'brandedGraphicsFront',
            label: 'Branded Graphics - Front',
            validationRules: [{required: true, message: 'Field is required'}],
            retailPrice: 200,
            wholesalePrice: 50,
        },
        {
            unitName: 'brandedGraphicsWrapped',
            label: 'Branded Graphics – Wrapped',
            validationRules: [{required: true, message: 'Field is required'}],
            retailPrice: 450,
            wholesalePrice: 250,
        },
        {
            unitName: 'onsiteInstallationAndTraining',
            label: 'Onsite Installation & Training',
            validationRules: [{required: true, message: 'Field is required'}],
            retailPrice: 750,
            wholesalePrice: 250,
        },
        {
            unitName: 'flatRateShipping',
            label: 'Flat Rate Shipping',
            validationRules: [
                {required: true, message: 'Field is required'},
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('flatRateShipping') >= 3) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Min value is 3'));
                    },
                })
            ],
            retailPrice: null,
            wholesalePrice: 500,
        },
        {
            unitName: 'softwareAndSalesSupport',
            label: 'Software & Sales Support - Billed Monthly',
            validationRules: [{required: true, message: 'Field is required'}],
            retailPrice: 29.95,
            wholesalePrice: 24.95,
        },
    ];

    const watchedFormFields = {
        vickiSmartStore: Form.useWatch('vickiSmartStore', form),
        brandedGraphicsFront: Form.useWatch('brandedGraphicsFront', form),
        brandedGraphicsWrapped: Form.useWatch('brandedGraphicsWrapped', form),
        onsiteInstallationAndTraining: Form.useWatch('onsiteInstallationAndTraining', form),
        flatRateShipping: Form.useWatch('flatRateShipping', form),
        salesTax: Form.useWatch('salesTax', form),
        softwareAndSalesSupport: Form.useWatch('softwareAndSalesSupport', form),
    };

    const formatPrice = (price) => {
        if (price === null) {
            return 'n/a';
        }

        if (price === 0) {
            return '';
        }

        return '$' + price.toFixed(2).toLocaleString();
    }

    const getUnitPrice = (purchasedUnit, kioskQuantity) => {
        if (!kioskQuantity) {
            return 0;
        }

        let price;

        if (kioskQuantity < 3) {
            price = purchasedUnit.retailPrice || 0;
        } else {
            price = purchasedUnit.wholesalePrice || 0;
        }

        return price;
    }

    const getUnitTotalPrice = (purchasedUnit, quantity, kioskQuantity) => {
        if (!quantity || !kioskQuantity) {
            return 0;
        }

        const unitPrice = getUnitPrice(purchasedUnit, kioskQuantity);

        return unitPrice ? (unitPrice * quantity) : 0;
    }
    
    const getProductForPurchase = (productName, quantity, kioskQuantity) => {
        const product = vickiKioskPricing.find(p => p.unitName === productName);
        return {
            name: product.label,
            quantity: quantity,
            price: getUnitPrice(product, kioskQuantity),
            total: getUnitTotalPrice(product, quantity, kioskQuantity)
        }
    }
    
    const calculateTotalProductsPrice = () => {
        const formData = form.getFieldsValue();
        const kioskQuantity = formData['vickiSmartStore'];
        let totalPrice = 0;

        productPropertyNames.forEach(prop => {
            const product = vickiKioskPricing.find(p => p.unitName === prop);
            if (formData[prop]) {
                totalPrice += getUnitTotalPrice(product, formData[prop], kioskQuantity);
            }
        });
        
        return totalPrice;
    }

    const onFinish =  async (formData) => {
        setLoading(true);

        formData.products = [];
        const kioskQuantity = formData['vickiSmartStore'];
        
        productPropertyNames.forEach(prop => {
            if (formData[prop]) {
                const product = getProductForPurchase(prop, formData[prop], kioskQuantity);
                formData.products.push(product)
                
                delete formData[prop];
            }
        });
        
        if (formData.invoiceAddress) {
            formData.address = formData.invoiceAddress;
            delete formData.invoiceAddress;
        }

        formData.salesTaxPercent = salesTax;
        formData.salesTaxValue = calculateTotalProductsPrice() * (salesTax / 100);
        formData.total = calculateTotalProductsPrice() * (1 + (salesTax / 100));
        try {
            const response = await fetch('https://viatouchmedia-test.apigee.net/loyalty/microsite/order?to=viktor.kazmirchuk@oril.co', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': props.accessToken
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Something went wrong!');
            }

            setLoading(false);
            messageApi.open({
                type: 'success',
                content: 'Order form successfully submitted!',
            });
            form.resetFields();
        } catch (err) {
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: 'Failed to submit Order form: ' + err.message,
            });
            console.log(err);
        }
    };

    return (
        <div className={'order-form-wrapper'}>
            {contextHolder}
            <Form
                form={form}
                layout="horizontal"
                labelAlign="left"
                labelWrap={true}
                colon={false}
                scrollToFirstError={true}
                onFinish={onFinish}
            >
                <div className={'order-form-section'}>
                    <div className={'order-section-title'}>
                        VIATOUCH QUOTE INFORMATION
                    </div>
                    <div className={'order-section-form'}>
                        <Form.Item label="Quote Number"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="quoteNumber"
                                   initialValue={'VTM-Planet Fitness'}
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input disabled={true}/>
                        </Form.Item>
                        <Form.Item label="Group Name"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="groupName"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Group Number (G0###)"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="groupNumber"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Shipping Address"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="shippingAddress"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Delivery Name & Contact"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="deliveryNameAndContact"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Payment Terms"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="paymentTerms"
                                   initialValue={'Net 30'}
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input disabled={true}/>
                        </Form.Item>
                        <Form.Item label="Shipping Terms"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="shippingTerms"
                                   initialValue={'F.O.B. Origin'}
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input disabled={true}/>
                        </Form.Item>
                        <Form.Item label="Total Quantity of Vicki’s"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="totalQuantity"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <InputNumber/>
                        </Form.Item>
                        <div>
                            * Quotes are valid for 30 days after receipt
                            <br/>
                            * Training dates must be agreed upon between parties prior to shipment delivery
                            <br/>
                            * Delivery takes 4-6 weeks from Order Placement
                        </div>
                    </div>
                </div>

                <div className={'order-form-section'}>
                    <div className={'order-section-title'}>Vicki Kiosk Pricing:</div>
                    <div className={'order-section-form'}>
                        <div className={'pricing-table'}>
                            <div className={'pricing-unit table-header'}>
                                <div className={'pricing-unit-column'}>Product Description</div>
                                <div className={'pricing-unit-column'}>Retail Kiosk Price</div>
                                <div className={'pricing-unit-column'}>PF Price Per Kiosk, 3 Kiosk Order</div>
                            </div>
                            {vickiKioskPricing.map((unit, index) => (
                                <div key={index} className={'pricing-unit'}>
                                    <div className={'pricing-unit-column'}>{unit.label}</div>
                                    <div className={'pricing-unit-column'}>{formatPrice(unit.retailPrice)}</div>
                                    <div className={'pricing-unit-column'}>{formatPrice(unit.wholesalePrice)}</div>
                                </div>
                            ))}
                        </div>

                        <div className={'mt-50'}>
                            * The equipment cost includes additional hardware – badge scanner and built in camera valued
                            at $900 for $433 – that were deemed to be required during the pilot phase
                            <br/>
                            * Branded graphics: Front graphics required. If unit is free standing – full unit wrap
                            recommended.
                            <br/>
                            * Flat rate shipping- $500 or less per machine depending on shipping zone
                            <br/>
                            * Onsite installation and training is $250 per machine
                        </div>
                    </div>
                </div>

                <div className={'order-form-section'}>
                    <div className={'order-section-title'}>
                        This order Form is for the purchase of the ViaTouch Media products and services set forth below:
                    </div>
                    <div className={'order-section-form'}>
                        <div className={'pricing-table'}>
                            <div className={'pricing-unit table-header'}>
                                <div className={'pricing-unit-column'}>Product Description</div>
                                <div className={'pricing-unit-column'}>Quantity</div>
                                <div className={'pricing-unit-column'}>Price</div>
                                <div className={'pricing-unit-column'}>Total</div>
                            </div>
                            {vickiKioskPricing.map((unit, index) => (
                                <div key={index} className={'pricing-unit'}>
                                    <div className={'pricing-unit-column'}>{unit.label}</div>
                                    <div className={'pricing-unit-column'}>
                                        <Form.Item name={unit.unitName}
                                                   rules={unit.validationRules}>
                                            <InputNumber />
                                        </Form.Item>
                                    </div>
                                    <div className={'pricing-unit-column'}>
                                        {watchedFormFields && watchedFormFields[unit.unitName] && watchedFormFields['vickiSmartStore'] ? formatPrice(getUnitPrice(unit, watchedFormFields['vickiSmartStore'])) : ''}
                                    </div>
                                    <div className={'pricing-unit-column'}>
                                        {watchedFormFields && watchedFormFields[unit.unitName] && watchedFormFields['vickiSmartStore'] ? formatPrice(getUnitTotalPrice(unit, watchedFormFields[unit.unitName], watchedFormFields['vickiSmartStore'])) : ''}
                                    </div>
                                </div>
                            ))}
                            <div className={'pricing-unit'}>
                                <div className={'pricing-unit-column'}>Sales Tax</div>
                                <div className={'pricing-unit-column'}></div>
                                <div className={'pricing-unit-column'}>{salesTax}%</div>
                                <div className={'pricing-unit-column'}>
                                    {formatPrice(calculateTotalProductsPrice() * (salesTax / 100))}
                                </div>
                            </div>
                            <div className={'pricing-unit'}>
                                <div className={'pricing-unit-column text-bold'}>Total</div>
                                <div className={'pricing-unit-column'}></div>
                                <div className={'pricing-unit-column'}></div>
                                <div className={'pricing-unit-column text-bold'}>
                                    {formatPrice(calculateTotalProductsPrice() * (1 + (salesTax / 100)))}
                                </div>
                            </div>
                        </div>

                        <div className={'mt-50'}>
                            * Travel & expenses which will be included on the final invoice once the installation is
                            complete. Will not exceed $500.00 per location.
                        </div>

                        <div className={'mt-50'}>
                            Product Hardware Warranty Term is 12-months self-parts replacement and begins as of the date
                            of
                            delivery. Software & Sales Support is covered for the life of the unit so long as the
                            monthly
                            service fee is paid. All warranty information will be included with the delivery of the
                            hardware & software. By executing this Order Form, you accept and agree to the terms of
                            ViaTouch Media – Planet Fitness Master Purchase Agreement.
                        </div>
                    </div>
                </div>

                <div className={'order-form-section'}>
                    <div className={'order-section-title'}>INVOICE TO</div>
                    <div className={'order-section-form'}>
                        <Form.Item label="Address"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="invoiceAddress"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Attn"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="attn">
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'PO Required on Invoice'}
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="poRequiredOnInvoice"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Radio.Group>
                                <Radio value="true"> Yes </Radio>
                                <Radio value="false"> No </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="If yes, PO #"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="po">
                            <InputNumber/>
                        </Form.Item>
                    </div>
                </div>

                <div className={'order-form-section'}>
                    <div className={'order-section-title'}>PLANET FITNESS CUSTOMER</div>
                    <div className={'order-section-form'}>
                        <Form.Item label="By"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="by"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Name"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="name"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Title"
                                   labelCol={{span: 6}}
                                   wrapperCol={{span: 18}}
                                   name="title"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Field is required',
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                    </div>
                </div>

                <div className={'order-form-section'}>
                    <div className={'order-section-title'}></div>
                    <div className={'order-section-form'}>
                        <Form.Item wrapperCol={{span: 24}}>
                            <Button type="primary"
                                    loading={loading}
                                    htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default OrderForm;
